import React from "react";
import CurlImportForm from "pages/Editor/CurlImport/CurlImportForm";
import ModalControls from "pages/Editor/CurlImport/ModalControls";
import { curlImportSubmitHandler } from "pages/Editor/CurlImport/helpers";
import { useSelector } from "react-redux";
import { getCurrentWorkflowId } from "@appsmith/selectors/workflowSelectors";
import { getNewEntityName } from "@appsmith/selectors/entitiesSelector";
import { DEFAULT_PREFIX } from "sagas/ActionSagas";
import {
  ActionParentEntityType,
  CreateNewActionKey,
} from "@appsmith/entities/Engine/actionHelpers";

const WorkflowCURLImportModal = () => {
  const workflowId = useSelector(getCurrentWorkflowId);
  const actionName = useSelector((state) =>
    getNewEntityName(state, {
      prefix: DEFAULT_PREFIX.API,
      parentEntityId: workflowId || "",
      parentEntityKey: CreateNewActionKey.WORKFLOW,
    }),
  );
  const initialFormValues = {
    contextId: workflowId,
    contextType: ActionParentEntityType.WORKFLOW,
    name: actionName,
  };
  return (
    <ModalControls>
      <CurlImportForm
        curlImportSubmitHandler={curlImportSubmitHandler}
        initialValues={initialFormValues}
      />
    </ModalControls>
  );
};

export default WorkflowCURLImportModal;
