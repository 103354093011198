import { useEffect, useMemo, useRef, useState } from "react";
import { getAllModules } from "@appsmith/selectors/modulesSelector";
import { useSelector } from "react-redux";
import { getEvaluationInverseDependencyMap } from "selectors/dataTreeSelectors";

export interface UseModuleReferenceTrackerProps {
  currentModuleName?: string;
}

interface ReturnValue {
  added: string[];
  removed: string[];
}

function getEntityName(item: string) {
  return item.split(".")[0];
}
function useModuleReferenceTracker({
  currentModuleName,
}: UseModuleReferenceTrackerProps) {
  const prevModuleNameRef = useRef(currentModuleName);
  const dependenciesRef = useRef<string[]>([]);
  const [changes, setChanges] = useState<ReturnValue>({
    added: [],
    removed: [],
  });
  const inverseDependencyMap = useSelector(getEvaluationInverseDependencyMap);
  const modules = useSelector(getAllModules);

  useEffect(() => {
    if (prevModuleNameRef.current !== currentModuleName) {
      dependenciesRef.current = [];
      prevModuleNameRef.current = currentModuleName;
    }
    const modulesList = Object.values(modules).map((m) => m.name);

    const newDependencies = new Set<string>();

    Object.entries(inverseDependencyMap).forEach(([key, deps]) => {
      const parentKey = getEntityName(key);
      deps.forEach((dep) => {
        const parentDep = getEntityName(dep);
        if (
          parentDep === currentModuleName &&
          parentDep !== parentKey &&
          modulesList.includes(parentKey)
        ) {
          newDependencies.add(parentKey);
        }
      });
    });

    const added = Array.from(newDependencies).filter(
      (dep) => !dependenciesRef.current.includes(dep),
    );
    const removed = dependenciesRef.current.filter(
      (dep) => !newDependencies.has(dep),
    );

    const updatedChanges: ReturnValue = {
      added: [],
      removed: [],
    };

    if (added.length > 0) {
      updatedChanges.added = added;
    }

    if (removed.length > 0) {
      updatedChanges.removed = removed;
    }

    dependenciesRef.current = Array.from(newDependencies);
    setChanges({ added, removed });
  }, [inverseDependencyMap, currentModuleName, modules]);

  return useMemo(() => changes, [changes]);
}

export default useModuleReferenceTracker;
