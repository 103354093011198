import React from "react";

import EntityExplorerSidebar from "components/editorComponents/EntityExplorerSidebar";
import Explorer from "../../PackageExplorer";
import Modules from "../../PackageExplorer/Modules";
import { useSelector } from "react-redux";
import {
  getCurrentModule,
  getHasPrivateEntity,
} from "@appsmith/selectors/modulesSelector";
import { getShowModuleReference } from "@appsmith/selectors/moduleFeatureSelectors";
import { MODULE_TYPE } from "@appsmith/constants/ModuleConstants";

function PackageEditorEntityExplorer() {
  const currentModule = useSelector(getCurrentModule);
  const hasPrivateEntity = useSelector(getHasPrivateEntity);
  const showModuleReference = useSelector(getShowModuleReference);

  const hideExplorer =
    (showModuleReference && !hasPrivateEntity) ||
    !Boolean(currentModule) ||
    currentModule?.type === MODULE_TYPE.QUERY;

  return (
    <EntityExplorerSidebar>
      {showModuleReference && <Modules showDivider={!hideExplorer} />}
      {!hideExplorer && <Explorer />}
    </EntityExplorerSidebar>
  );
}

export default PackageEditorEntityExplorer;
