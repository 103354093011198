import React, { useEffect } from "react";
import { Button, Modal, ModalContent, ModalHeader, Text } from "design-system";
import styled from "styled-components";
import {
  ARE_YOU_SURE,
  CANCEL,
  WORKFLOW_REGENERATE_CTA,
  WORKFLOW_REGENERATE_MODAL_DESC,
  createMessage,
} from "@appsmith/constants/messages";

interface Props {
  isOpen: boolean;
  isWorkflowTokenGenerating: boolean;
  token: string;
  onClose: () => void;
  onRegenerate: () => void;
}

const InputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: 16px;
  gap: 16px;
  width: 100%;
`;

export const RegenerateTokenModal = (props: Props) => {
  const { isOpen, isWorkflowTokenGenerating, onClose, onRegenerate, token } =
    props;

  useEffect(() => {
    if (isOpen && token.length > 0) {
      onClose();
    }
  }, [token]);

  return (
    <Modal onOpenChange={(open) => !open && onClose()} open={isOpen}>
      <ModalContent data-testid="t--workflow-regenerate-token-modal">
        <ModalHeader>{createMessage(ARE_YOU_SURE)}</ModalHeader>
        <Text kind="body-m">
          {createMessage(WORKFLOW_REGENERATE_MODAL_DESC)}
        </Text>
        <InputWrapper>
          <Button
            data-testid="t--workflow-regenerate-token-modal-negative-cta"
            kind="secondary"
            onClick={onClose}
            size="md"
          >
            <Text kind="body-m">{createMessage(CANCEL)}</Text>
          </Button>
          <Button
            data-testid="t--workflow-regenerate-token-modal-positive-cta"
            isLoading={isWorkflowTokenGenerating}
            kind="primary"
            onClick={onRegenerate}
            size="md"
          >
            <Text kind="body-m">{createMessage(WORKFLOW_REGENERATE_CTA)}</Text>
          </Button>
        </InputWrapper>
      </ModalContent>
    </Modal>
  );
};
