import React from "react";
import EditorWrapperContainer from "pages/Editor/commons/EditorWrapperContainer";
import PackageEditorEntityExplorer from "./PackageEditorEntityExplorer";
import { useSelector } from "react-redux";
import { getCurrentModule } from "@appsmith/selectors/modulesSelector";
import { MODULE_TYPE } from "@appsmith/constants/ModuleConstants";
import { Divider } from "design-system";
import { getShowModuleReference } from "@appsmith/selectors/moduleFeatureSelectors";

function PackageMainContainer() {
  const showModuleReference = useSelector(getShowModuleReference);
  const currentModule = useSelector(getCurrentModule);
  // TODO: Remove this when private entities are deprecated.
  const showExplorer =
    Boolean(currentModule) && currentModule?.type !== MODULE_TYPE.QUERY;

  if (!showExplorer && !showModuleReference) return null;

  return (
    <>
      <EditorWrapperContainer>
        <PackageEditorEntityExplorer />
      </EditorWrapperContainer>
      <Divider orientation="vertical" />
    </>
  );
}

export default PackageMainContainer;
